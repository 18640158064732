<template>
  <div>
    <div
      v-for="Item in Items"
      :key="Item"
      class="client"
    >
      <router-link :to="{ name: 'Warehouse-show', params: { id: Item.id } }">
        <b-card>
          <b-card-title>
            <h3>Przedmiot: {{ Item.name }}</h3>
          </b-card-title>
          <b-img
            :src="Item.image"
            class="col-xl-2 rounded"
          />
          <b-card-body style="font-size: 1.3em; color: black; font-weight: 500;">
            Na stanie: {{ Item.quantity }}
          </b-card-body>
        </b-card>
      </router-link>
    </div>
    <div v-if="!Items[0]">
      <b-card>
        <H1 style="text-align: center;">
          Hej! Nie masz żadnych przedmiotów w magazynie. <br> <router-link to="/Dodaj-przedmiot-magazynowy">
            Dodaj przedmioty
          </router-link>
        </H1>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCalendar, BCalendarDay, BCard, BForm, BCardHeader, BImg,
} from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  name: 'Calendar',
  components: {
    BCalendar,
    BCalendarDay,
    BCard,
    BForm,
    BCardHeader,
    BImg,
  },
  data() {
    return {
      Items: {},
    }
  },
  mounted() {
    document.title = 'Magazyn - Pickmode'

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}warehouse`, config)
      .then(response => {
        this.Items = response.data.warehouseItems
      })
      .catch(error => {
        console.log(error)
      })
  },

}
</script>

<style>

</style>
